* {
    box-sizing: border-box;
}

body {
    background-color: #EFEFEF;
    font-family: 'Tele-GroteskHal', Arial, Helvetica, sans-serif;
    z-index: 0;
    font-size: 18px;
    line-height: 26px;
}

h1 {
    font-size: 54px;
    line-height: 60px;
    font-weight: normal;
}

h2 {
    font-size: 34px;
    line-height: 42px;
    font-weight: normal;
}

h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: normal;
}

h4 {
    font-size: 18px;
    line-height: 22px;
    font-weight: normal;
}

h5 {
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
    color: #6A6A6A;
}

.h1 {
    font-size: 60px !important;
    line-height: 64px !important;
    font-weight: normal !important;
}

.h2 {
    font-size: 38px !important;
    line-height: 42px !important;
    font-weight: normal !important;
}

.h3 {
    font-size: 24px !important;
    line-height: 30px !important;
    font-weight: normal !important;
}

.h4 {
    font-size: 18px !important;
    line-height: 22px !important;
    font-weight: normal !important;
}

.h5 {
    font-size: 16px !important;
    line-height: 18px !important;
    font-weight: normal !important;
}

strong {
    font-family: 'Tele-GroteskUlt', Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.txt-bold {
    font-family: 'Tele-GroteskUlt', Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.txt-center {
    text-align: center !important;
}