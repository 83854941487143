@import '../../scss/variables';

.link_wrapper {
  text-decoration: none;

  .titlecard {
    display: flex;
    background-color: $white;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 5px;
    color: $dark-gray;

    .titlecard_left {
      display: flex;
      align-items: center;

      .titlecard_left_round {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        background-color: $light-gray;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 30px;
          height: 30px;
          color: #858585;
        }
      }

      .titlecard_left_title {
        margin: 0;
        margin-left: 12px;
      }
    }
  }
}
