@import '../../scss/variables';

.teamroster {
  display: flex;
  flex-direction: column;
  color: $dark-gray;
  font-size: 14px;

  @media (min-width: $grid-breakpoint-sm) {
    height: 100%;
    min-height: calc(100vh - #{$navbar-height});
  }

  .teamroster_hero {
    background-color: $white;
    padding: 48px 48px 24px;
    width: 100%;

    > p {
      color: $light-gray;
      margin: 0;
      margin-bottom: 1em;
      font-weight: 300;

      > a {
        color: $light-gray;
        font-style: italic;
        text-decoration: none;

        &:hover {
          color: $dark-gray;
          text-decoration: underline;
          transition: all 0.3s ease-in-out;
        }
      }

      > span {
        color: $dark-gray;
      }
    }

    .teamroster_hero_left {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .teamroster_hero_heading {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        .teamroster_hero_logo_wrapper {
          display: flex;
          background-color: $light-gray;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 100px;
          height: 100px;
          min-width: 100px;
          min-height: 100px;
          z-index: 0;

          .teamroster_hero_logo {
            max-width: 100%;
            height: auto;
          }
        }

        .teamroster_hero_logo_heading_headings {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 1em;

          > p {
            margin-top: 12px;
            margin-bottom: 0;
          }

          > h1 {
            margin: 0;
          }
        }
      }

      .teamroster_hero_member {
        background-color: $dark-gray;
        color: $background;
        padding: 16px;
        margin-top: 32px;
        border-radius: 5px;
        display: flex;
        align-items: center;

        > div {
          margin-left: 16px;

          > p {
            margin: 0;

            + p {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }

  .teamroster_roster {
    padding: 24px 48px;
    background-color: $background;
    height: 100%;

    > section:not(:first-child) {
      margin-top: 24px;
    }

    .teamroster_add_wrapper {
      border-bottom: 1px solid $dark-gray;
      margin-bottom: 24px;
    }
  }
}

.teamroster_card_wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 1em;
  margin-top: 16px;
}

.teamroster_hero_member_request_button {
  color: $white;
  background-color: $dark-gray;
  width: 100%;
  border: 2px solid $dark-gray;
  font-weight: 300;
  border-radius: 5px;
  padding: 16px 0;

  &:hover {
    background-color: $darker-gray;
    border: 2px solid $darker-gray;
    transition: all ease-in-out 0.3s;
  }
}

.teamroster_hero_member_request_button__secondary {
  width: 100%;
  font-weight: 300;
  border-radius: 5px;
  padding: 16px 0;
  background-color: $light-gray;
  border: 2px solid $light-gray;

  &:hover {
    background-color: $background;
    transition: all ease-in-out 0.3s;
  }
}

.teamroster_nonmember {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > p {
    margin-top: 16px;
    text-align: center;
  }
}

.member_modal_title {
  color: $dark-gray;
}

.member_form_error {
  color: $notification-red;
  margin-top: 12px;
}

.member_form_button {
  margin-top: 12px;
  width: 100%;
  background-color: $dark-gray;
  color: $white;
  font-weight: 300;
  border: none;
  border-radius: 5px;
  padding: 16px 0;
  text-transform: uppercase;

  &:hover {
    background-color: $darker-gray;
    transition: all ease-in-out 0.3s;
  }
}
