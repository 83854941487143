.btn-primary {
  color: #fff !important;
}

// Remove Ugly Apple Styles
textarea,
input.text,
input[type='select'],
input[type='text'],
input[type='button'],
input[type='submit'],
.input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
}
