@import '../../scss/variables';

.link_wrapper {
  text-decoration: none;
  color: $dark-gray;
  border-radius: 5px;
  margin-bottom: 16px;
  background-color: #FFFFFF;
  border: 1px solid $light-gray;

  &:hover {
    color: $darker-gray;
    transition: all 0.3s ease-in-out;
    background-color: #EEEEEE;
  }

  &.myClub {
    background-color: rgba(254, 168, 129, 0.3);

    &:hover {
      background-color: rgba(254, 168, 129, 0.5);
    }
  }

  .clubcard {
    display: flex;
    width: 100%;

    .clubcard_image_wrapper {
      display: flex;
      background-color: $white;
      justify-content: center;
      align-items: center;
      max-width: 110px;
      min-width: 110px;
      height: 80px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      padding: 0 10px;

      .clubcard_image {
        max-width: 100%;
        height: auto;
        max-height: 100%;
        padding: 3px 0;
      }
    }

    .clubcard_info_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 1em;
      border-left: 1px solid #EEE;

      .clubcard_info_left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: auto;

        > h3 {
          font-size: 16px;
        }
        > p {
          font-size: 14px;
          margin: 0;
        }
      }

      .clubcard_info_right {
        > svg > path {
          stroke: $dark-gray;
          stroke-width: 1;
        }
      }
    }
  }
}
