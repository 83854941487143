@import '../../scss/variables';

.clubhome {
  display: flex;
  flex-direction: column;
  color: $dark-gray;
  font-size: 14px;

  @media (min-width: $grid-breakpoint-sm) {
    height: calc(100vh - #{$navbar-height});
    min-height: calc(100vh - #{$navbar-height});
  }

  .clubhome_hero {
    background-color: $background;
    background-image: linear-gradient(
      $background,
      $background 60%,
      $light-gray 60%
    );
    padding: 48px 48px 24px;
    width: 100%;

    @media (min-width: $grid-breakpoint-md) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2em;

      background-image: none;
    }

    .clubhome_hero_left {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .clubhome_hero_heading {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
  
        @media (max-width: $grid-breakpoint-sm) {
          flex-direction: column;
        }

        .clubhome_hero_logo_wrapper {
          display: flex;
          background-color: $white;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 100px;
          height: 100px;
          min-width: 100px;
          min-height: 100px;
          z-index: 0;

          .clubhome_hero_logo {
            max-width: 100%;
            height: auto;
          }
        }

        .clubhome_hero_logo_heading_headings {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 1em;

          .clubhome_hero_logo_heading_headings_welcome {
            margin: 0;
            color: $darker-gray;
            text-transform: uppercase;
            font-size: 14px;
          }

          > p {
            margin-top: 12px;
            margin-bottom: 0;
          }

          > h1 {
            margin: 0;
          }
        }
      }

      .clubhome_hero_member {
        background-color: $dark-gray;
        color: $background;
        padding: 16px;
        margin-top: 32px;
        border-radius: 5px;
        display: flex;
        align-items: center;

        > div {
          margin-left: 16px;

          > p {
            margin: 0;

            + p {
              text-transform: capitalize;
            }
          }
        }
      }
    }

    .clubhome_hero_snapshot {
      background-color: $white;
      padding: 16px;
      margin-top: 32px;
      border-radius: 5px;
      box-shadow: 0 0 4px rgba(0,0,0, .5);

      @media (min-width: $grid-breakpoint-md) {
        margin-top: 0;
      }

      .clubhome_hero_snapshot_table {
        width: 100%;
        color: $dark-gray;

        > thead > tr > th {
          text-align: left;
          font-weight: 400;
          padding: 6px 0;
        }

        > tbody > tr {
          border-top: 2px solid $light-gray;

          > td {
            padding: 12px 0;
          }
        }
      }
    }
  }

  .clubhome_management {
    padding: 24px 48px;
    background-color: $light-gray;
    height: 100%;

    > section:not(:first-child) {
      margin-top: 24px;
    }
  }
}

.clubhome_card_wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 1em;
  margin-top: 16px;
}

.clubhome_hero_member_request_button {
  color: $white;
  background-color: $dark-gray;
  width: 100%;
  border: 2px solid $dark-gray;
  font-weight: 300;
  border-radius: 5px;
  padding: 16px 0;

  &:hover {
    background-color: $darker-gray;
    border: 2px solid $darker-gray;
    transition: all ease-in-out 0.3s;
  }
}

.clubhome_hero_member_request_button__secondary {
  width: 100%;
  font-weight: 300;
  border-radius: 5px;
  padding: 16px 0;
  background-color: $light-gray;
  border: 2px solid $light-gray;

  &:hover {
    background-color: $background;
    transition: all ease-in-out 0.3s;
  }
}

.clubhome_nonmember {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > p {
    margin-top: 16px;
    text-align: center;
  }
}
