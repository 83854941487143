@import '../../scss/variables';

.clubroster {
  height: 100%;
  min-height: calc(100vh - #{$navbar-height-sm});
  @media (min-width: $grid-breakpoint-sm) {
    height: 100%;
    min-height: calc(100vh - #{$navbar-height});
  }
  display: flex;
  flex-direction: column;
  color: $dark-gray;
  font-size: 14px;

  .clubroster_hero {
    background-color: $white;
    padding: 48px 48px 24px;
    height: 20vh;
    min-height: 140px;
    width: 100%;

    > p {
      color: $light-gray;
      margin: 0;
      margin-bottom: 1em;
      font-weight: 300;

      > a {
        color: $light-gray;
        font-style: italic;
        text-decoration: none;

        &:hover {
          color: $dark-gray;
          text-decoration: underline;
          transition: all 0.3s ease-in-out;
        }
      }

      > span {
        color: $dark-gray;
      }
    }
  }

  .clubroster_body {
    background-color: $background;
    width: 100%;
    height: 80vh;
    min-height: 600px;
    padding: 24px 48px;
    display: flex;
    flex-direction: column;
  }

  .clubroster_body_tablist {
    list-style-type: none;
    padding: 0;
  }

  .clubroster_body_tab {
    border: none;
    display: inline;
    margin-right: 1.5em;
    cursor: pointer;
    color: $dark-gray;
    text-transform: uppercase;

    .clubroster_body_tab_link {
      text-decoration: none;
      color: $dark-gray;
    }
  }

  .clubroster_body_tab__selected {
    border-bottom: 2px solid $dark-gray;
    padding: 0.25em 0;
  }

  .clubroster_invite_wrapper {
    border-bottom: 1px solid $dark-gray;
    margin-bottom: 24px;
  }

  section {
    margin-top: 24px;
  }
}

.invite_modal_title {
  color: $dark-gray;
}

.invite_form_label {
  color: $dark-gray;
}

.invite_form_input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1em;
  color: $darker-gray;
  font-size: 16px;
  font-family: inherit;
  padding: 0.25em 0.5em;
  background-color: $white;
  border: none;
  border-bottom: 2px solid $light-gray;

  &:focus {
    outline: none;
    border-bottom: 2px solid $dark-gray;
  }
}

.invite_form_button {
  margin-top: 12px;
  width: 100%;
  background-color: $dark-gray;
  color: $white;
  font-weight: 300;
  border: none;
  border-radius: 5px;
  padding: 16px 0;

  &:hover {
    background-color: $darker-gray;
    transition: all ease-in-out 0.3s;
  }
}

.invite_form_error {
  color: $notification-red;
  margin-top: 12px;
}
