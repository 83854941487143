@import '../../scss/variables';

.rosteritem {
  display: flex;
  align-items: center;
  padding: 12px 10px;
  margin-bottom: 12px;
  color: $dark-gray;
  border-radius: 5px;
  border: 1px solid #cccccc;

  .rosteritem_round {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    background-color: $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rosteritem_text_wrapper {
    margin-left: 12px;
    .rosteritem_title {
      margin: 0;
      font-weight: 500;
    }
    .rosteritem_desc {
      margin: 0;
      font-weight: 300;
      text-transform: capitalize;
    }
  }
}

.rosteritem_button {
  cursor: pointer;

  &:hover {
    background-color: $light-gray;
    transition: all 0.3s ease-in-out;
  }
}

a {
  text-decoration: none;
}

.align-center {
  align-content: center;
}