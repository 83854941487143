@import '../../scss/variables';

.clubschedules {
  height: 100%;
  min-height: calc(100vh - #{$navbar-height-sm});
  @media (min-width: $grid-breakpoint-sm) {
    height: calc(100vh - #{$navbar-height});
    min-height: calc(100vh - #{$navbar-height});
  }
  display: flex;
  flex-direction: column;
  color: $dark-gray;
  font-size: 14px;

  .clubschedules_hero {
    background-color: $white;
    padding: 48px 48px 24px;
    height: 20vh;
    min-height: 140px;
    width: 100%;

    > p {
      color: $light-gray;
      margin: 0;
      font-weight: 300;

      > a {
        color: $light-gray;
        font-style: italic;
        text-decoration: none;

        &:hover {
          color: $dark-gray;
          text-decoration: underline;
          transition: all 0.3s ease-in-out;
        }
      }

      > span {
        color: $dark-gray;
      }
    }
  }

  .clubschedules_body {
    background-color: $background;
    width: 100%;
    height: 80vh;
    min-height: 600px;
    padding: 24px 48px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }

  .clubschedules_body_tablist {
    list-style-type: none;
    padding: 0;
  }

  .clubschedules_body_tab {
    border: none;
    display: inline;
    margin-right: 1.5em;
    cursor: pointer;
    color: $dark-gray;
    text-transform: uppercase;

    .clubschedules_body_tab_link {
      text-decoration: none;
      color: $dark-gray;
    }
  }

  .clubschedules_body_tab__selected {
    border-bottom: 2px solid $dark-gray;
    padding: 0.25em 0;
  }

  .clubschedules_invite_wrapper {
    border-bottom: 1px solid $dark-gray;
    margin-bottom: 24px;
  }

  section {
    margin-top: 24px;
  }
}

.invite_modal_title {
  color: $dark-gray;
}

.activity_form_label {
  color: $dark-gray;
}

.activity_form_input_checkbox {
  display: inline-block;
  margin: 0.25em 0.5em;
  color: $darker-gray;
}

.activity_form_input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1em;
  color: $darker-gray;
  font-size: 16px;
  font-family: inherit;
  padding: 0.25em 0.5em;
  background-color: $white;
  border: none;
  border-bottom: 2px solid $light-gray;

  &:focus {
    outline: none;
    border-bottom: 2px solid $dark-gray;
  }
}

.activity_form_textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1em;
  color: $darker-gray;
  font-size: 16px;
  font-family: inherit;
  padding: 0.25em 0.5em;
  background-color: $white;
  border: none;
  border: 1px solid $light-gray;

  &:focus {
    outline: none;
    border-bottom: 2px solid $dark-gray;
  }
}

.activity_form_button,
.delete_club_button {
  margin-top: 12px;
  width: 100%;
  background-color: $dark-gray;
  color: $white;
  font-weight: 300;
  border: none;
  border-radius: 5px;
  padding: 16px 0;

  &:hover {
    background-color: $darker-gray;
    transition: all ease-in-out 0.3s;
  }
}

.delete_club_button {
  background-color: $notification-red;
  padding-left: 20px;
  padding-right: 20px;
}

.delete_club_button:disabled {
  opacity: 0.5;
  background-color: $darker-gray;
  cursor: not-allowed;
}

.activity_form_error {
  color: $notification-red;
  margin-top: 12px;
}

// ******************************************************************************************
// ******************************************************************************************
//   DELETE THIS JUNK AFTER WE ACTUALLY GET VISUAL DESIGN
// ******************************************************************************************
// ******************************************************************************************

.filter {
  font-size: 14px;
  line-height: 21px;
  font-weight: bold;
  padding: 15px 45px 15px 15px;
  color: white;
  background-color: #6c6c6c;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.eventMonth {
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

.eventDay {
  font-size: 24px;
  line-height: 23px;
  font-weight: 800;
  letter-spacing: -2px;
}

.scheduleTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-bottom: 40px;
}

.scheduleTable thead tr th {
  color: #a7a7a7;
  padding: 0 20px;
  font-size: 16px;
}

@media (max-width: 576px) {
  .scheduleTable thead tr th {
    padding: 0 15px;
  }
}

.scheduleTable thead tr td {
  color: #a7a7a7;
}

.scheduleTable tbody tr {
  color: #707070;
  background-color: $accent1;
  height: 80px;
}

.scheduleTable tbody tr td {
  font-size: 20px;
  padding: 0.5em 20px;
}

@media (max-width: 576px) {
  .scheduleTable tbody tr td {
    padding: 0.5em 15px;
  }
}

.scheduleTable tbody tr td:nth-child(1) {
  text-align: center;
  white-space: nowrap;
  color: black;
  background-color: #cecece;
  width: 1%;
}

.scheduleTable tbody tr td:last-child {
  width: 1%;
  padding-left: 0;
  padding-right: 0;
}

.eventName {
  font-weight: bold;
  a {
    text-decoration: none;
    font-size: 20px;
  }
}

.eventLocationSmall {
  font-size: 14px;
  line-height: 17px;
}

.navTab {
  color: white;
  font-family: Impact, sans-serif;
  font-size: 21px;
  line-height: 30px;
}
.navTabActive {
  color: #ff7700;
  text-decoration: underline;
}

.tableFixedWidth tbody td {
  width: 20%;
}

.attendingToggle {
  display: none;
}

.attendingRow {
  display: none;
  padding: 0 !important;

  &.open {
    display: flex;
  }

  grid-column-start: 1;
  grid-column-end: span 6;
}

.toggleLabel {
  padding: 15px;
}

.athleteNameAttending,
.athleteNameNotAttending,
.athleteNameNotPaid {
  margin-bottom: 0;
  font-size: 16px !important;
}

.notPaidAlert {
  color: $notification-red;
}

// ^^^
// ******************************************************************************************
// ******************************************************************************************
//   DELETE THIS JUNK AFTER WE ACTUALLY GET VISUAL DESIGN
// ******************************************************************************************
// ******************************************************************************************

.activityGrid {
  display: grid;
  grid-template-columns: 110px 1fr 1fr 1fr auto 55px;
  margin-bottom: 35px;
  max-width: 100%;
  &:not(:first-of-type) {
    box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.05),
      2px 2px 4px rgba(0, 0, 0, 0.05);
  }
}

.activityHeadingRow {
  display: contents;
  > div {
    color: #a7a7a7;
    padding: 0 20px;
    font-size: 16px;
    font-weight: bold;
  }
}

.activityRow {
  display: contents;
  > div {
    background-color: $accent1;
    color: $dark-gray;
    padding: 10px 20px;
    font-size: 20px;
    align-items: start;
    text-align: center;
  }
  div:first-child {
    color: $dark-gray;
    align-items: center;
    background-color: $light-gray;
  }
}

@media (max-width: 576px) {
  .clubschedules {
    .clubschedules_body {
      padding: 24px 15px;
    }
  }
  .activityGrid {
    grid-row-gap: 0;
  }

  .activityRow {
    div {
      grid-column-start: 1;
      grid-column-end: span 6;
      max-width: 100%;
      align-items: center !important;
    }
  }
}
