@keyframes fadeInFastOutSlow {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  30% {
    opacity: 0.9;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeInInstantOutSlow {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  40% {
    opacity: 0.9;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

#streak1 {
  animation: fadeInFastOutSlow 3s infinite both;
}
#streak2 {
  animation: fadeInFastOutSlow 3s 0.5s infinite both;
}
#streak3 {
  animation: fadeInFastOutSlow 3s 1s infinite both;
}
#bullet1 {
  animation: fadeInInstantOutSlow 3s -0.1s infinite both;
}
#bullet2 {
  animation: fadeInInstantOutSlow 3s 0.4s infinite both;
}
#bullet3 {
  animation: fadeInInstantOutSlow 3s 0.9s infinite both;
}
