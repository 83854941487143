// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$notification-red: #f00;
$notification-orange: #ffba00;

// Grays
$white: #fff;
$background: #eee;
$light-gray: #cccccc;
$dark-gray: #707070;
$darker-gray: #606060;

// Theme Colors
$background: #fff;
$accent1: #f4f4f4;

// Shotdeck Colors
$shotdeck-orange: #ff4f00;
$shotdeck-darkgray: #414141;

:root {
  --toastify-color-info: #ff4f00 !important;
  --toastify-color-progress-info: #ff4f00 !important;
} // Navbar
$navbar-height: 78px;
$navbar-height-sm: 50px;

$grey: #707070;
$light-grey: #dbdbdb;
$light-grey--hover: #f4f4f4;

// Breakpoints
$grid-breakpoint-xs: 0;
$grid-breakpoint-sm: 576px;
$grid-breakpoint-md: 768px;
$grid-breakpoint-lg: 992px;
$grid-breakpoint-xl: 1200px;
$grid-breakpoint-xxl: 1400px;

//Used in margin, padding, and other spacing utilities
$spacers: (
  0: 0,
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
  6: 6px,
  7: 7px,
  8: 8px,
  9: 9px,
  10: 10px,
  11: 11px,
  12: 12px,
  13: 13px,
  14: 14px,
  15: 15px,
  16: 16px,
  17: 17px,
  18: 18px,
  19: 19px,
  20: 20px,
  21: 21px,
  22: 22px,
  23: 23px,
  24: 24px,
  25: 25px,
  26: 26px,
  27: 27px,
  28: 28px,
  29: 29px,
  30: 30px,
  35: 35px,
  40: 40px,
  45: 45px,
  50: 50px,
  55: 55px,
  60: 60px,
  65: 65px,
  70: 70px,
  75: 75px,
  80: 80px,
  85: 85px,
  90: 90px,
  95: 95px,
  100: 100px,
) !default;

$font-sizes: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
  6: 6px,
  7: 7px,
  8: 8px,
  9: 9px,
  10: 10px,
  11: 11px,
  12: 12px,
  13: 13px,
  14: 14px,
  15: 15px,
  16: 16px,
  17: 17px,
  18: 18px,
  19: 19px,
  20: 20px,
  21: 21px,
  22: 22px,
  23: 23px,
  24: 24px,
) !default;

// Bootstrap Color system
$primary: $shotdeck-orange !default;
// $secondary:     $gray-600 !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;
