@import '../../scss/variables';

.dashboard {
  height: 100%;
  min-height: calc(100vh - #{$navbar-height-sm});
  @media (min-width: $grid-breakpoint-sm) {
    height: calc(100vh - #{$navbar-height});
    min-height: calc(100vh - #{$navbar-height});
  }
  display: flex;
  flex-direction: column;
  color: #fff;

  @media (min-width: $grid-breakpoint-md) {
    flex-direction: row;
  }

  .dashboard_hero {
    background-color: $shotdeck-orange;
    padding: 48px;
    height: 35vh;
    min-height: 250px;
    width: 100%;
    align-items: center;
    display: flex;

    @media (min-width: $grid-breakpoint-md) {
      height: 100%;
      min-height: calc(100vh - #{$navbar-height-sm});
      @media (min-width: $grid-breakpoint-sm) {
        height: 100%;
        min-height: calc(100vh - #{$navbar-height});
      }
    }

    .dashboard_hero_column {
      z-index: 1;

      .dashboard_hero_heading {
        font-weight: bold;
      }

      .dashboard_hero_desc {
        font-size: 16px;
      }
    }

    .dashboard_hero_bg {
      border-radius: 50%;
      margin-left: -64px;
      width: 175px;
      height: 175px;
      min-width: 175px;
      min-height: 175px;
      background-size: cover;
      background-color: $accent1;
      z-index: 0;
    }
  }

  .dashboard_clubs {
    height: 65vh;
    background-color: $background;
    min-height: 400px;
    width: 100%;
    padding: 24px 48px;
    display: flex;
    flex-direction: column;
    overflow: scroll;

    @media (max-width: $grid-breakpoint-sm) {
      overflow: auto;
      height: auto;
      padding: 12px 24px;
    }

    @media (min-width: $grid-breakpoint-md) {
      height: 100%;
      min-height: calc(100vh - #{$navbar-height-sm});
      @media (min-width: $grid-breakpoint-sm) {
        height: 100%;
        min-height: calc(100vh - #{$navbar-height});
      }
      justify-content: center;
    }

    .dashboard_clubs_heading {
      color: #707070;
      margin: 16px 0;
      font-size: 1.2rem;
    }

    .dashboard_clubFinder {
      display: flex;
      flex-direction: column;
      padding: 10px;
      background-color: #f6f6f6;
      height: 40vh;
      overflow: scroll;
      margin-bottom: 32px;
      box-shadow: 0px 0px 2px rgba(0,0,0,.5);
    }

    .dashboard_clubs_link_right {
      float: right;

      a {
        color: #707070;
        font-size: 1rem;

        &:hover {
          color: #606060;
          text-decoration: none;
        }
      }
    }

    .dashboard_clubs_invite {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      background-color: $light-gray;
      padding: 1em;

      .dashboard_clubs_invite_left {
        display: flex;
        flex-direction: column;

        > p {
          margin: 0;
          > span {
            text-transform: capitalize;
          }
        }
      }

      .dashboard_clubs_invite_right {
        > button {
          all: unset;
          cursor: pointer;

          + button {
            margin-left: 16px;
          }

          &:hover {
            transform: scale(1.1);
            transition: all 0.3s ease-in-out;
          }

          &:focus {
            outline: $dark-gray 5px auto;
          }
        }
      }
    }
  }
}
