@import '../../scss/variables';

.signup {
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  @media (min-width: $grid-breakpoint-md) {
    flex-direction: row;
  }

  .signup_hero_wrapper {
    background-color: $light-gray;
    padding: 48px;
    height: 50vh;
    min-height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: $grid-breakpoint-md) {
      height: 100vh;
    }

    .signup_hero {
      display: flex;
      align-items: center;

      .signup_hero_heading {
        color: $dark-gray;
        font-weight: bold;
        z-index: 1;
      }

      .signup_hero_bg {
        border-radius: 50%;
        margin-left: -48px;
        width: 175px;
        height: 175px;
        min-width: 175px;
        min-height: 175px;
        background-color: $background;
        z-index: 0;
      }
    }
  }

  .signup_form {
    height: 50vh;
    min-height: 400px;
    width: 100%;
    padding: 24px 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: $grid-breakpoint-md) {
      height: 100vh;
    }

    .signup_form_label {
      visibility: hidden;
      width: 0;
      height: 0;
    }

    .signup_form_input_group {
      position: relative;

      .signup_form_input_icon {
        position: absolute;
        top: 4px;
        color: $light-gray;
      }

      .signup_form_input {
        display: block;
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 1em;
        font-size: 16px;
        font-family: inherit;
        padding: 0.25em 0.5em 0.5em 2.5em;
        background-color: $white;
        border: none;
        border-bottom: 2px solid $light-gray;

        &:focus {
          outline: none;
          border-bottom: 2px solid $dark-gray;
        }
      }
    }

    .signup_form_link_wrapper {
      text-align: center;

      .signup_form_link {
        color: $dark-gray;
        text-decoration: none;
        text-align: center;

        &:hover {
          color: $darker-gray;
          text-decoration: underline;
          transition: all ease-in-out 0.3s;
        }
      }
    }

    .signup_form_error {
      color: $notification-red;
      margin-top: 12px;
    }

    .signup_form_button {
      margin-top: 12px;
      width: 100%;
      background-color: $dark-gray;
      color: $white;
      font-weight: 300;
      border: none;
      border-radius: 5px;
      padding: 16px 0;

      &:hover {
        background-color: $darker-gray;
        transition: all ease-in-out 0.3s;
      }
    }

    .signup_form_or {
      padding: 24px 0;
      display: flex;
      flex-direction: row;
      color: $light-gray;

      &:before,
      &:after {
        content: '';
        flex: 1 1;
        border-bottom: 1px solid $light-gray;
        margin: auto;
      }

      &:before {
        margin-right: 30px;
      }
      &:after {
        margin-left: 30px;
      }
    }
  }
}
