.scoreInput {
  width: 100%;
  border: 0;
  text-align: center;
  background-color: transparent;
  -moz-appearance: textfield; // gets rid of number scroller
}

/* Gets rid of number scroller on Chrome, Safari, Edge, Opera */
.scoreInput::-webkit-outer-spin-button,
.scoreInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputDenominator {
  border-top: 2px solid darkgray;
  text-align: center;
}
