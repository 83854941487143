@import '../../scss/_variables.scss';

/** The red bubble on nav icons to show the number of notifications
$numberBubble_top-offset-percentage       num     0(0%)-1(100%) The percentage of the diameter which should be above the navBar icon,
$numberBubble_right-offset-percentage     num     0(0%)-1(100%) The percentage of the diameter which should be right of the navBar icon,
$numberBubble_diameter                    px
$numberBubble_font-size                   px
$item-margin-right           px
$item-height                 px
$navbar-height               px
$navbar-padding              px                 */
@mixin navBar(
  $numberBubble_top-offset-percentage,
  $numberBubble_right-offset-percentage,
  $numberBubble_diameter,
  $numberBubble_font-size,
  $item-margin-right,
  $item-height,
  $navbar-height,
  $navbar-padding
) {
  .navBar {
    height: $navbar-height;
    //Sets the offset from center
    padding: $navbar-padding;

    background-color: #f1f3f6;
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;
    z-index: 1000;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    .siteLogo {
      height: 40px;
    }

    @media screen and (max-width: 500px) {
      .siteLogo {
        height: 30px;
      }
    }

    .item {
      margin-right: $item-margin-right;
      position: relative;

      .numberBubble {
        top: -$numberBubble_diameter * $numberBubble_top-offset-percentage;
        right: -$numberBubble_diameter * $numberBubble_right-offset-percentage;
        font-size: $numberBubble_font-size;
        height: $numberBubble_diameter;
        min-width: $numberBubble_diameter;
        border-radius: $numberBubble_diameter / 2;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: $notification-red;
      }

      .numberBubbleInline {
        font-size: $numberBubble_font-size;
        line-height: 13px;
        height: $numberBubble_diameter;
        min-width: $numberBubble_diameter;
        border-radius: $numberBubble_diameter / 2;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: $notification-red;
      }

      .navIcon {
        height: $item-height;
      }

      .navAnchor {
        color: #afafaf;
      }
    }
  }
}

@include navBar(
  $numberBubble_top-offset-percentage: 0.5,
  $numberBubble_right-offset-percentage: 0.25,
  $numberBubble_diameter: 20px,
  $numberBubble_font-size: 12px,
  $item-margin-right: 20px,
  $item-height: 32px,
  $navbar-height: $navbar-height,
  $navbar-padding: 0 0 0 20px
);

@media (max-width: $grid-breakpoint-sm) {
  @include navBar(
    $numberBubble_top-offset-percentage: 0.5,
    $numberBubble_right-offset-percentage: 0.25,
    $numberBubble_diameter: 15px,
    $numberBubble_font-size: 9px,
    $item-margin-right: 20px,
    $item-height: 28px,
    $navbar-height: $navbar-height-sm,
    $navbar-padding: 0px 0 0 20px
  );
}

.Task {
  border-left-color: $notification-orange !important;
}
.Announcement {
  border-left-color: $notification-red !important;
}

.dropdownNav {
  &.hidden {
    max-height: 0;
    transition-duration: 0.1s;
    overflow: hidden;
  }

  overflow: visible;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  display: flex;
  flex-direction: column;
  right: -50px;
  top: 86px;
  background-color: white;
  width: 544px;
  max-height: 2000px;
  transition-duration: 0.1s;
  color: $grey;

  a {
    color: $grey;
    text-decoration: none;
  }

  .dropdownItem {
    &:global(:not(.viewed)) {
      border-left: 3px solid;
    }

    &:global(.viewed) {
      background-color: $light-grey;
    }
    &:hover {
      background-color: $light-grey--hover;
    }

    display: flex;
    padding: 12.5px 24px 12.5px 15px;
    border-top: 2px solid $light-grey;
    font-size: 14px;

    .text {
      max-width: 80%;
    }

    .image {
      width: 50px !important;
      margin-right: 15px;
    }

    .time {
      font-size: 10px;
      margin-bottom: 10px;
    }

    .title {
      font-weight: bold;
      font-size: 14px;
    }
  }

  &:before {
    content: '\A';
    border-style: solid;
    border-width: 10px 22px 14px 0;
    border-color: transparent #fff transparent transparent;
    position: absolute;
    right: 65px;
    top: -15px;
    transform: rotate(-33deg);
  }
}
