@import '../../scss/variables';

.profile {
  height: 100%;
  min-height: 250px;
  color: $dark-gray;

  @media (min-width: $grid-breakpoint-md) {
    flex-direction: row;
  }

  .profile_hero,
  .dashboard_profile {
    padding: 50px 150px;
  }

  .profile_hero {
    background-color: $background;
    min-height: 250px;
    width: 100%;
    align-items: center;
    display: flex;

    .profile_hero_image_wrapper {
      display: flex;
      justify-content: center;
      margin-right: 30px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 992px) {
      justify-content: center;
    }

    @media screen and (max-width: 768px) {
      display: block;
      margin: 0 auto;
      padding: 50px 150px;

      .profile_hero_info_wrapper {
        margin: 0 0 20px;
        text-align: center;
      }
    }

    @media screen and (max-width: 575px) {
      padding: 50px 15px;
      justify-content: center;
    }

    .profile_hero_image_wrapper {
      margin-left: 30px;
      > img {
        height: 170px;
        width: 170px;
      }
    }

    .profile_hero_info_wrapper {
      display: block;
      min-width: 250px;
    }

    .profile_hero_name {
      margin-bottom: 20px;
      font-size: 28px;
    }

    .profile_hero_column {
      z-index: 1;

      .profile_hero_heading {
        font-weight: bold;
      }

      .dashboard_hero_desc {
        font-size: 16px;
      }
    }

    .profile_hero_bg {
      border-radius: 50%;
      margin-left: -64px;
      width: 175px;
      height: 175px;
      min-width: 175px;
      min-height: 175px;
      background-color: $background;
      z-index: 0;
    }

    h2,
    h6 {
      font-weight: 600;
      color: $shotdeck-darkgray;
    }
  }

  .dashboard_profile {
    height: 65vh;
    background-color: $white;
    min-height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: $grid-breakpoint-md) {
      height: 100%;
      min-height: calc(100vh - #{$navbar-height-sm});
      justify-content: flex-start;
    }

    @media (min-width: $grid-breakpoint-sm) {
      height: 100%;
      min-height: calc(100vh - #{$navbar-height});
    }

    .dashboard_clubs_heading {
      margin: 16px 0;
    }

    .dashboard_clubs_invite {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      background-color: $light-gray;
      padding: 1em;

      .dashboard_clubs_invite_left {
        display: flex;
        flex-direction: column;

        > p {
          margin: 0;
          > span {
            text-transform: capitalize;
          }
        }
      }

      .dashboard_clubs_invite_right {
        > button {
          all: unset;
          cursor: pointer;

          + button {
            margin-left: 16px;
          }

          &:hover {
            transform: scale(1.1);
            transition: all 0.3s ease-in-out;
          }

          &:focus {
            outline: $dark-gray 5px auto;
          }
        }
      }
    }
  }

  .orange_btn,
  .gray_btn {
    display: block;
    font-weight: 500;
    margin: 15px 0;
    width: 100%;
  }

  .orange_btn {
    background-color: $shotdeck-orange;
    border: 1px solid $shotdeck-orange;
  }

  .gray_btn {
    background-color: $shotdeck-darkgray;
    border: 1px solid $shotdeck-darkgray;
  }

  .orange_btn:hover,
  .orange_btn:focus,
  .orange_btn:active,
  .gray_btn:hover,
  .gray_btn:focus,
  .gray_btn:active {
    outline: none;
    opacity: 0.7;
    text-decoration: none;
    box-shadow: none;
  }

  .mb_25 {
    margin-bottom: 25px;
  }

  .profile_card_wrapper {
    background-color: $background;
    margin: 12px 0;
    padding: 30px 50px;
    height: 100%;

    .profile_card_header {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    .profile_card_subheader {
      font-size: 16px;
      text-transform: uppercase;
      padding-bottom: 15px;
    }
    .profile_card_subheader:last-of-type {
      border-bottom: 1px solid $dark-gray;
    }

    table {
      width: 100%;

      tr {
        border-bottom: 2px solid $dark-gray;
      }
      tr:last-child {
        border-bottom: none;
      }

      td {
        font-size: 18px;
        width: 50%;
        padding: 25px 0;
      }
      td:first-child {
        border-right: 2px solid $dark-gray;
      }
      td:last-child {
        text-align: right;
      }
    }

    ul {
      padding-left: 0;
      margin-bottom: 20px;
    }

    ul li {
      display: inline-block;
      background-color: $shotdeck-orange;
      color: white;
      padding: 10px 15px;
      margin-right: 15px;
      margin-bottom: 20px;
    }
  }

  .profile_awards_wrapper {
    background-color: $white;
    padding: 30px 40px;
    text-align: center;
    margin-bottom: 25px;

    @media screen and (min-width: $grid-breakpoint-xl) {
      margin-bottom: 0;
    }
  }

  .profile_awards_image {
    margin-bottom: 10px;
  }

  .profile_awards_title {
    margin-bottom: 20px;
    height: 50px;
  }

  .profile_edit {
    position: relative;
    overflow: hidden;
    margin-top: -38px;
    border: none;
    border-radius: 0;
    font-size: 15px;
    font-weight: 700;
    background: rgba(33, 37, 41, 0.72);
    cursor: pointer;
    color: #fff;
    text-align: center;
    min-width: 100%;
    padding: 0.5rem 1rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;

    &:hover {
      background: rgba(33, 37, 41, 0.5);
    }
  }

  .profile_item_wrapper {
    margin-bottom: 10px;
  }

  .profile_picture_wrapper {
    margin-bottom: 30px;

    img {
      @media (min-width: $grid-breakpoint-lg) {
        min-height: 170px;
      }
    }
  }

  .profile_item {
    font-weight: 700;
  }

  .profile_body_tab__selected {
    border-bottom: 3px solid $shotdeck-orange;
    padding: 0.25em 0;
  }

  .profile_body_tablist {
    list-style-type: none;
    padding: 2px;
    border-bottom: 1px solid $light-gray;
  }

  .profile_body_tab {
    display: inline;
    margin-right: 1.5em;
    cursor: pointer;
    color: $dark-gray;
    text-transform: uppercase;

    .profile_body_tab_link {
      text-decoration: none;
      color: $dark-gray;
      font-weight: 600;
    }
  }

  .profile_button {
    margin-top: 12px;
    width: 100%;
    background-color: #707070;
    border: 2px solid #707070;
    color: #fff;
    font-weight: 300;
    border-radius: 5px;
    padding: 10px 0;
    margin-bottom: 30px;

    &:hover {
      background-color: #606060;
      border: 2px solid #606060;
      transition: all ease-in-out 0.3s;
    }
  }
}
